<template>
    <div v-if="newComp">
        <span v-if="type==1" class="welcome-txt flex">
            <span><span class="ellipsis1" style="color:#F9F9F9;max-width:200px">{{newComp.name}}</span></span>欢迎您
            <span v-if="newComp.cert_stat==0" @click="goApprove()" style="margin-left:20px;color: #fff;cursor: pointer;">未认证</span>
            <span @click="approveLink()" v-if="newComp.cert_stat==1" style="margin-left:20px;color: #fff;">认证中</span>
            <span @click="approveLink()" v-if="newComp.cert_stat==3" style="margin-left:20px;color: #fff;">认证失败</span>
        </span>

        <span v-if="type==2" class="approve-txt welcome-txt flex">
            <span v-if="newComp.cert_stat==0" @click="goApprove()" style="margin-left:10px;color: #5074EE;cursor: pointer;">去认证</span>
            <span @click="approveLink()" v-if="newComp.cert_stat==1" style="margin-left:10px;color: #5074EE;">认证中</span>
            <span @click="approveLink()" v-if="newComp.cert_stat==3" style="margin-left:10px;color: #F66F6A;">认证失败</span>
        </span>

        <el-dialog width="500px" @close="close" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true" :append-to-body="true">
            <div v-if="popTitle == '企业认证'">
                <Sign :popTitle="popTitle" @data="refreshItems" @hide="hide" @signState="signState"></Sign>
            </div>
            <div v-if="popTitle == '遇到问题'">
                <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { goCompanyVerify,getCompInfoById } from '@/service/company';
    import Sign from '@/components/sign/sign.vue';
    import SignBack from '@/components/sign/signBack.vue';
    export default {
        components: {
            Sign,SignBack
        },
        props: ['comp','type'],
        data() {
            return {
                popVisible: false,
                popTitle: null,
                newComp:null,
            }
        },
        watch: {
            'comp.cert_stat':{
                handler() {
                    this.newComp = this.comp;
                },
                deep: true
            },
        },
        computed: {},
        created () {
            this.newComp = this.comp;
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                if (this.$store.state.loginUser.user.comp_creator==1) {
                    getCompInfoById(this.$store.state.loginUser.comp.id).then(rst => {
                        if (rst) {
                            this.newComp = rst;
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
            hide() {
                this.popVisible = false;
                this.$message.success('状态已更新');
            },
            signState(text) {
                this.popTitle = text;
                this.popVisible = true;
            },
            goApprove() {
                let legal = {
                    comp_id:this.newComp.id,
                }
                goCompanyVerify(legal).then(rst => {
                    if (rst.verify_url) {
                        window.open(rst.verify_url);
                        this.signState("企业认证");
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            close() {
                this.refreshItems();
            },
            approveLink() {
                if (this.newComp.fdd_verify_url) {
                    window.open(this.newComp.fdd_verify_url);
                    this.signState("企业认证");
                }
            },
        }
    }
</script>
<style scoped>
    .welcome-txt {
        font-weight: 500;
        color: #CCCCCC;
        font-size: 16px;
        /* margin-right: 80px;
        margin-left: 30px; */
    }
    .welcome-txt>span {
        cursor: pointer;
    }
    .ellipsis1 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .welcome-txt.approve-txt {
        font-size: 14px;
        color: #5074EE;
    }
</style>