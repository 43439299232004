<template>
  <div class="zt-page-content">
    <el-row v-if="isComplianced" class="not-complianced">
      <div class="zt-block demand-query">
        <el-form :model="searchForm" inline style="width: 100%; height: 40px">
          <el-row type="flex" align="middle">
            <el-col :span="20">
              <el-form-item label="申请日期">
                <el-date-picker
                  v-model="searchForm.daterange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 380px"
                  value-format="timestamp"
                  @change="getDate"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="当前审批状态">
                <el-select
                  v-model="searchForm.state"
                  placeholder="全部"
                  @change="handleChange"
                >
                  <el-option
                    v-for="item in orderStateOption"
                    :key="item.optionCode"
                    :label="item.optionValue"
                    :value="item.optionCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="align-r">
              <el-form-item>
                <el-button size="small" type="primary" @click="goSubmitApply"
                  >提交新申请</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="block" style="height: calc(100% - 92px)">
        <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
          <el-table :data="list" style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              width="100"
              align="center"
            >
              <template slot-scope="scope">{{
                queryOpt.page.pageCount * (queryOpt.page.pageNumber - 1) +
                scope.$index +
                1
              }}</template>
            </el-table-column>
            <!-- <el-table-column
              prop="id"
              label="申请编号"
              width="320"
              align="center"
            ></el-table-column> -->
            <el-table-column prop="submitAt" label="申请日期" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.submitAt">
                  {{ scope.row.submitAt | moment("YYYY/MM/DD") }}
                </div>
                <div v-else>--</div>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="当前审批状态" align="center">
              <template slot-scope="scope">
                <!-- 已保存待提交 2 审核中 3 审核不通过 4 审核通过 5 公示 6 发放奖补 -->
                <div v-if="scope.row.state == 1">--</div>
                <div v-if="[2].includes(scope.row.state)">审核中</div>
                <div v-if="scope.row.state == 3">审核不通过</div>
                <div v-if="[4, 5, 6].includes(scope.row.state)">审核通过</div>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="审批后处理" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.state == 1">--</div>
                <div v-if="scope.row.state == 2">--</div>
                <div v-if="scope.row.state == 3">--</div>
                <div v-if="scope.row.state == 12">--</div>
                <div v-if="scope.row.state == 4">暂无</div>
                <div v-if="scope.row.state == 5">已签约</div>
                <div v-if="scope.row.state == 6">已公示</div>
                <div v-if="scope.row.state == 7">奖补发放</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-row>
                  <el-col>
                    <el-button
                      @click="
                        goto(
                          '/factory/gov/designAllowance/detail/' +
                            scope.row.id +
                            '?type=' +
                            scope.row.state
                        )
                      "
                      type="text"
                      >申请信息</el-button
                    >
                    <!-- 流程结束才显示下载按钮 -->
                    <el-button
                      v-if="[3, 4, 5, 6, 7].includes(scope.row.state)"
                      @click="singleAffixDownload(scope.row)"
                      type="text"
                      >申请表下载</el-button
                    >
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 12px">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page.pageNumber"
            :page-size="queryOpt.page.pageCount"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </el-row>
    <el-row v-else class="p20">
      <h3 class="qualification-title">申请条件</h3>
      <Qualification></Qualification>
      <el-row>
        <el-col>
          <el-row
            type="flex"
            justify="center"
            align="middle"
            class="image-data"
          >
            <img
              style="width: 110px"
              :src="staticUrl + '/design/placeholder/meiyougengduo.png'"
              alt
            />
            <p class="flex flex-align-center">您的企业还未认证，暂时无法申请
              <CertState
                v-if="logedUser.comp"
                :comp="logedUser.comp"
                :type="2"
              ></CertState>
            </p>
          </el-row>
        </el-col>
        <el-col class="service"
          >如有疑问拨打客服电话:19970936393(工作日 8:30~17:30)</el-col
        >
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { STATIC_URL_PRE } from "@/config";
import Qualification from "@/components/approve/Qualification.vue";
import { designAllowanceList } from "@/service/factory";
import CertState from "@/views/layout/StateCert.vue";
export default {
  components: {
    Qualification,
    CertState
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
  },
  computed: {
    isComplianced() {
      return this.$store.state.loginUser.comp.cert_stat == 2; // 是否已认证
      // return this.$store.state.loginUser.comp.is_in_spec === 1;
    },
    logedUser() {
      return this.$store.state.loginUser;
    },
    orderStateOption() {
      return [
        {
          optionCode: -1,
          optionValue: "全部",
        },
        {
          optionCode: 2,
          optionValue: "审核中",
        },
        {
          optionCode: 3,
          optionValue: "审核不通过",
        },
        {
          optionCode: 4,
          optionValue: "审核通过",
        },
      ];
    },
  },
  data() {
    return {
      isActive: true,
      staticUrl: STATIC_URL_PRE,
      popVisible: false,
      popTitle: null,
      total: 0,
      list: [],
      comp: null,
      ruleForm: "",
      searchForm: {
        daterange: [],
        state: "",
        keywords: "",
      },
      queryOpt: {
        where: {
          submitAt: {
            start: null,
            end: null,
          },
          states: [1, 2, 3, 4, 5, 6], //1 已保存待提交 2 审核中 3 审核不通过 4 审核通过 5 公示 6 发放奖补
          key: null,
          approveState: null,
        },
        page: {
          pageNumber: 1,
          pageCount: 15,
        },
        order: [["createdAt", "desc"]],
      },
    };
  },

  created() {
    this.refreshItems();
    this.debounceRefreshItems = debounce(this.refreshItems, 500);
  },
  methods: {
    goSubmitApply() {
      if (this.$store.state.loginUser.comp.cert_stat != 2) {
        this.$message.error("请先完成企业认证后才可申请");
        return false;
      }
      this.goto('/factory/gov/designAllowance/detail/add?type=13')
    },
    getDate(e) {
      if (e) {
        this.queryOpt.where.submitAt.start = e[0];
        this.queryOpt.where.submitAt.end = e[1];
      } else {
        this.queryOpt.where.submitAt.start = null;
        this.queryOpt.where.submitAt.end = null;
      }
    },
    handleChange() {
      if (this.searchForm.state === -1) {
        this.queryOpt.where.states = [1, 2, 3, 4, 5, 6];
      } else if (this.searchForm.state === 4) {
        this.queryOpt.where.states = [4, 5, 6];
      } else {
        this.queryOpt.where.states = [this.searchForm.state];
      }
    },
    search() {
      this.queryOpt.where.key = this.searchForm.keywords;
    },
    singleAffixDownload(row) {
      const { compName, approves, id } = row;
      this.$confirm("下载为当前预览格式,确认下载?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          // 设计中心或者升规办不通过 a3 就不存在,只会有一份文件
          let a1 = approves.find((item) => item.code === "2501");
          let a3 = approves.find((item) => item.code === "2503");
          const fileData = [
            {
              folderName: "申请表",
              fileData: !a3
                ? [a1.contract_no]
                : [a1.contract_no, a3.contract_no],
            },
          ];
          this.exportZip(fileData, `${compName}${id}申报材料`);
        })
        .catch((e) => {
          console.error(e.message);
          this.$message.info("取消");
        });
    },
    // 单条申请记录导出申报材料
    exportZip(fileData, fileName) {
      import("@/vendor/Export2Zip")
        .then((excel) => {
          excel.export_file_to_zip1(
            fileData,
            `${fileName}${this.$moment().format("YYYYMMDDHHmmss")}`
          );
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    goto(link) {
      this.$router.push(link);
    },
    refreshItems() {
      this.list = [];
      designAllowanceList(this.queryOpt)
        .then((rst) => {
          if (rst && rst.rows.length > 0) {
            this.list = rst.rows;
            this.total = rst.count;
            this.copyList = rst.rows;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style scoped src="../../style.css"></style>
<style scoped>
div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__body {
  background: #f5f5f5;
}
</style>
<style lang="less" scoped>
.not-complianced {
  padding: 20px 30px 0 30px;
}

.image-data {
  margin: 40px 0;
}

.service {
  text-align: center;
  font-weight: 500;
}
</style>